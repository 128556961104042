import { Table, Modal } from 'antd'
import { strToDateTime } from '../../utils/transferDate'
import { revokeExamination } from '../../request/examination'
import { useSelector } from 'react-redux'
import {
  TeacherType,
  StudentType,
  ManagerType,
} from '../../settings/ProComponents/ProLayoutSetting'
import { useState } from 'react'
import { pageSize } from './common'
import { useNavigate } from 'react-router-dom'
import { STATUS_CHOICE } from '../../utils/constant'
import { useStorageMsg } from '../../hooks'
import { check as checkExam } from '../../request/examination'

const BackLog = ({
  data,
  handlePage,
  pageNum,
  update,
  isLoading,
  onPageSizeChange,
  page_size,
}) => {
  const navigate = useNavigate()
  const userType = useSelector(
    (state) => state && state.user && state.user.userType,
  )
  const [modal, holderContext] = Modal.useModal()
  // 列
  const columns = [
    {
      title: '来源',
      dataIndex: 'platform',
      key: 'platform',
      width: 150,
      sorter: true,
    },
    {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      sorter: true,
    },
    {
      title: '时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render(_, data) {
        return strToDateTime(data.created_at)
      },
      sorter: true,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 150,
      render(_, data) {
        return (
          <>
            {userType === TeacherType ? (
              <div className='yl-table-actions'>
                <div
                  className='yl-table-btn'
                  onClick={() => revokeInform(data)}>
                  撤销通知
                </div>
                {/* <div className='yl-table-btn' onClick={() => handleTest(data)}>测试</div> */}
                {data.category === 'exam' && (
                  <div
                    className='yl-table-btn'
                    onClick={() => handleTest(data)}>
                    测试
                  </div>
                )}
                {data.category === 'view_grades' && (
                  <div
                    className='yl-table-btn'
                    onClick={() => viewResult(data)}>
                    查看成绩
                  </div>
                )}
              </div>
            ) : userType === ManagerType ? (
              <div className='yl-table-actions'>
                {data.obj_info.status === '0' ? (
                  <div className='yl-table-btn' onClick={() => check(data)}>
                    审核
                  </div>
                ) : (
                  <div
                    className='yl-table-btn'
                    style={{ color: '#666', cursor: 'unset' }}>
                    {STATUS_CHOICE[data.obj_info.status]}
                  </div>
                )}
              </div>
            ) : userType === StudentType ? (
              <div className='yl-table-actions'>
                {data.category === 'exam' && (
                  <div
                    className='yl-table-btn'
                    onClick={() => handleTest(data)}>
                    测试
                  </div>
                )}
                {data.category === 'view_grades' && (
                  <div
                    className='yl-table-btn'
                    onClick={() => viewResult(data)}>
                    查看成绩
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        )
      },
    },
  ]

  // 测试
  const handleTest = (data) => {
    checkExam(data.obj_id).then((res) => {
      if (res.status === 200) {
        navigate(`/subject/exam/${data.obj_id}/${data.obj_id}/`)
      }
    })
  }

  // 查看成绩
  const viewResult = (data) => {
    navigate(`/examPaperResult/${1}/${data.obj_id}?from=todo`)
  }

  // 审核
  const check = (data) => {
    const url =
      window.location.origin +
      `/subject/edit/${data.obj_info.paper_id}?reviseId=${data.obj_id}`
    window.open(url)
  }

  // 撤销通知
  const revokeInform = async (data) => {
    modal.confirm({
      title: '撤回通知',
      content: `确定撤回${data.content}?`,
      onOk(cb) {
        cb()
        revokeExamination(data.inform).then((res) => {
          update()
        })
      },
      onCancel(cb) {
        cb()
      },
    })
  }

  // 监听审核结果
  useStorageMsg(
    (value) => {
      if (value.type !== 'update-revise') return
      if (data.results && Array.isArray(data.results)) {
        data.results.forEach((item) => {
          if (item.obj_id === value.payload.id) {
            update()
          }
        })
      }
    },
    [data],
  )
  // 改变page_size
  function changePageSize(page, page_size) {
    onPageSizeChange(page, page_size)
  }
  return (
    <>
      <Table
        onChange={handlePage}
        pagination={{
          defaultPageSize: pageSize,
          total: data.count,
          current: pageNum,
          showSizeChanger: true,
          onShowSizeChange: changePageSize,
        }}
        rowKey='id'
        scroll={{ y: 300 }}
        loading={isLoading}
        columns={columns}
        dataSource={data.results}
      />
      {holderContext}
    </>
  )
}

export default BackLog
